import * as React from "react"
import { graphql } from 'gatsby'
import { linkResolver } from '../utils/link_resolver'
import Seo from '../components/seo'
import Footer from '../components/footer'
import Header from '../components/header'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogEntryPage = ({data}) => {
  const entry = data.prismicBlog
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname={linkResolver(entry.data)} />
      <Header />
      <div className="bg-black">
        <div className="w-full">
          <div className="flex justify-around text-center">
            <div className="w-10/12 md:w-9/12 py-16">
              <div className="leading-snug text-2xl text-white font-poppins font-semibold">
                <h2 className="mb-8">
                  <span className="bg-white text-2xl lg:text-3xl text-orange px-3 py-1 font-bold leading-tight inline-block">{entry.data.heading}</span>
                </h2>
                <p className="ml-2">{entry.data.snippet}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pt-16 pb-16 bg-white rounded-tr-[133px]">
          <div className="w-10/12 md:w-7/12 mx-auto max-w-screen-xl">
            <div className="prose-a:break-all font-poppins max-w-none prose prose-img:rounded-tr-[27px] prose-img:rounded-tl-[27px] prose-img:rounded-bl-[27px] prose-h2:text-orange prose-h2:mb-6 prose-h2:mt-6 prose-h2:font-semibold prose-p:font-medium leading-snug">
              <GatsbyImage className="w-full md:w-60 h-full md:h-60 block object-cover float-left mr-8 mb-8" image={entry.data.image.gatsbyImageData} alt={entry.data.image.alt ?? ''} />
              <PrismicRichText linkResolver={linkResolver} field={entry.data.text.richText} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export const query = graphql`
query($handle: String) {
  prismicBlog(uid: {eq: $handle}) {
    uid
    data {
      meta_description
      page_title
      heading
      snippet
      image {
        gatsbyImageData
        alt
      }
      text {
        richText
      }
    }
    uid
  }
}
`

export default BlogEntryPage
